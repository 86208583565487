class Elementor_Scroll_Timeline {
    static instance;

    static getInstance() {
        if (!Elementor_Scroll_Timeline.instance) {
            Elementor_Scroll_Timeline.instance = new Elementor_Scroll_Timeline();
        }
        return Elementor_Scroll_Timeline.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-scroll-timeline.default', ($scope) => {
            let mainTop = $scope.find('.scroll-timeline-main').offset().top;

            let scrollTarget = false,
                timeline = $scope.find('.scroll-timeline-nav'),
                items = $('li', timeline),
                milestones = $scope.find('.scroll-timeline-wrapper .st-section');

            items.find('span').click(function () {
                let li = $(this).parent(),
                    index = li.index(),
                    milestone = milestones.eq(index);
                if (!li.hasClass('active') && milestone.length) {
                    scrollTarget = index;

                    let scrollTargetTop = milestone.offset().top + $('.scroll-timeline-main', $scope).scrollTop() - mainTop;
                    $('.scroll-timeline-main', $scope).animate({scrollTop: scrollTargetTop}, {
                        duration: 400,
                        complete: function complete() {
                            scrollTarget = false;
                        }
                    });

                }
            });

            $('.scroll-timeline-main', $scope).scroll(function () {
                let scrollTop = $(this).scrollTop();
                let viewLine = mainTop + scrollTop + $(this).height() / 3,
                    active = -1;


                if (scrollTarget === false) {
                    milestones.each(function () {
                        if ($(this).offset().top + scrollTop - viewLine > 0) {
                            return false;
                        }

                        active++;
                    });
                } else {
                    active = scrollTarget;
                }

                console.log(viewLine, '=== viewLine ===');
                console.log(active, '=== viewLine ===');

                items.filter('.active').removeClass('active');
                items.eq(active !== -1 ? active : 0).addClass('active');
            }).trigger('scroll');
        });
    }
}

Elementor_Scroll_Timeline.getInstance();